.error-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 600px;
  justify-content: center;

  p {
    margin-top: 10px;
    font-size: 24px;
  }
}
