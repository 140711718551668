@import '../variables';

.header {
  border-bottom: 1px solid $gray-line;
  padding: 40px 0;

  @media (max-width: 650px) {
    padding: 20px;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1000px) {
      display: grid;
      grid-template-columns: 3fr 1fr;

      .search-input {
        margin-top: 40px;
        width: 100%;
        grid-area: 2 / 1 / 2 / 3;
      }
    }

    @media (max-width: 650px) {
      grid-template-columns: 1fr;

      .search-input {
        margin-top: 20px;
        width: 100%;
        grid-area: 3 / 1;
      }

      .header__cart {
        margin-top: 20px;
      }

      .button--cart {
        margin: 0 auto;
      }
    }
  }

  &__logo {
    display: flex;

    img {
      margin-right: 15px;
    }

    h1 {
      color: #181818;
      font-size: 24px;
      letter-spacing: 1%;
      text-transform: uppercase;
      font-weight: 800;
    }

    p {
      color: #7b7b7b;
    }
  }

  .content__logo {
    width: 265px;

    @media (max-width: 460px) {
      width: 200px;
    }
  }

  .search-input {
    position: relative;
  }
}
